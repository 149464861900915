import { isDevelopment } from '@/utils/env'

import { ASSET_PREFIX } from '@/configs/constants'

export function interceptAssetDomain({
  url,
  type,
}: {
  url: string
  type: 'pdf' | 'cover'
}) {
  let publicationAssetPrefix = ASSET_PREFIX.replace(
    'website-assets',
    'publication-assets'
  )

  // Override the publicationAssetPrefix if the environment is development
  // Pointing to our staging CDN
  if (isDevelopment) {
    publicationAssetPrefix =
      'https://cdn.data-portal.staging.belajar.id/publication-assets'
  }

  if (type === 'cover') {
    return url.replace(
      'https://publikasi.data.kemdikbud.go.id/upload/sampul/',
      `${publicationAssetPrefix}/cover/`
    )
  }

  return url.replace(
    'https://publikasi.data.kemdikbud.go.id/upload/file/',
    `${publicationAssetPrefix}/pdf/`
  )
}
