'use client'

import { ErrorBoundary } from 'react-error-boundary'

import { Banner, BannerDescription } from '@wartek-id/banner'
import { Text } from '@wartek-id/text'

function FallbackPdfFrame({ error }) {
  return (
    <Banner title="Failed preview Pdf" type="critical" closable>
      <BannerDescription>
        <p>{error.message}</p>
      </BannerDescription>
    </Banner>
  )
}

export const PdfFrame = ({ url, height = 400 }) => {
  return (
    <ErrorBoundary FallbackComponent={FallbackPdfFrame}>
      <div className="flex flex-col gap-4">
        <Text variant="heading-md">Pratinjau PDF</Text>
        <iframe
          src={`${url}#view=fitH`}
          height={`${height}px`}
          width="100%"
          className="rounded-lg"
          data-testid="pdf-frame"
        ></iframe>
      </div>
    </ErrorBoundary>
  )
}
