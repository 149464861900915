'use client'

import React from 'react'

import { Button } from '@wartek-id/button'
import {
  Dialog,
  DialogAction,
  DialogCloseButton,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
} from '@wartek-id/dialog'
import { Text } from '@wartek-id/text'

export const ErrorDialog = ({
  show,
  onClose,
  title,
  message,
}: {
  title: string
  show: boolean
  onClose: () => void
  message: string | React.ReactNode
}) => {
  return (
    <Dialog isOpen={show} onClose={onClose} size="md">
      <DialogOverlay />
      <DialogContent className="md:!w-[600px] md:!max-w-[600px]">
        <DialogHeader title={title} variant="compact" className="!pr-[50px]" />
        <div
          style={{
            padding: 20,
          }}
        >
          {typeof message === 'string' ? (
            <Text>{message}</Text>
          ) : (
            <>{message}</>
          )}
        </div>
        <DialogFooter className="gap-2">
          <DialogAction as={Button} onClick={onClose}>
            Tutup
          </DialogAction>
        </DialogFooter>
        <DialogCloseButton data-testid="btn-close-dialog" />
      </DialogContent>
    </Dialog>
  )
}
