import { ChangeEvent } from 'react'

import { Icon } from '@wartek-id/icon'
import { Select } from '@wartek-id/select'
import { Text } from '@wartek-id/text'

import { JENIS_DOCUMENT } from '@/apis/data/jenisDocumentMapping'
import { RQStatus } from '@/apis/types'

interface FilterTypeProps {
  loadingStatus: RQStatus
  value: string
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void
}

export const FilterType = ({
  value,
  onChange,
  loadingStatus,
}: FilterTypeProps) => {
  return (
    <div className="flex flex-col items-start gap-2 md:flex-row md:items-center">
      <Text variant="body-sm-bold" className="w-[95px]" id="label-select-type">
        Tipe Publikasi
      </Text>
      <div className="w-full md:w-[150px]">
        <Select
          data-testid="combo-type"
          value={value}
          onChange={onChange}
          disabled={loadingStatus === 'pending'}
          dropdownIcon={<Icon>expand_more</Icon>}
          aria-label="Tipe Publikasi"
        >
          <option value="">Semua</option>
          {JENIS_DOCUMENT.map((item) => (
            <option value={item.VALUE} key={item.ID}>
              {item.LABEL}
            </option>
          ))}
        </Select>
      </div>
    </div>
  )
}
