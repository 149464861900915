import {
  useInfiniteQuery,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query'

import { fetchOnClient } from '@/utils/fetcher/fetcher.client'

import { ConfigCacheOneDay } from '@/configs/constants'

import {
  InfinitePublicationParams,
  PublicationFilterResponse,
  PublicationParams,
  PublicationResponse,
} from './types'
import {
  ENDPOINT,
  getNextPageParam,
  getPreviousPageParam,
} from '../utils/fetcher/fetcher.helpers'

export function getSearchFilterParams(
  params: Pick<PublicationParams, 'filters'>
): string {
  let queryString = ``
  if (params.filters) {
    if (params.filters.periode) {
      queryString = queryString + `&yearPeriod=${params.filters.periode}`
    }
    if (params.filters.type) {
      queryString = queryString + `&type=${params.filters.type}`
    }
    if (params.filters.query) {
      queryString = queryString + `&keyword=${params.filters.query}`
    }
    if (params.filters.sortBy) {
      queryString = queryString + `&sortBy=${params.filters.sortBy}`
    } else {
      queryString = queryString + `&sortBy=SORT_BY_CREATED_AT_DESC`
    }
  } else {
    queryString = queryString + `&sortBy=SORT_BY_CREATED_AT_DESC`
  }

  return queryString
}

export const useFetchPublications = (
  params: PublicationParams = { offset: 0, limit: 4 }
): UseQueryResult<PublicationResponse, Error> => {
  const pageParams = `offset=${params.offset}&limit=${params.limit}`
  const searchParams = getSearchFilterParams(params)
  const queryString = pageParams + searchParams
  return useQuery<PublicationResponse, Error>({
    queryKey: ['publications', queryString],
    queryFn: async (): Promise<PublicationResponse> => {
      return fetchOnClient<PublicationResponse>(
        `${ENDPOINT.PUBLICATION}/search?${queryString}`
      )
    },
    ...ConfigCacheOneDay,
  })
}

export const useFetchInifinitePublications = (
  params: InfinitePublicationParams = { limit: 8 },
  { enabled }: { enabled: boolean }
) => {
  // Do not set the offset here
  // We will get it from the pageParam argument
  const limitParams = `limit=${params.limit}`
  const searchParams = getSearchFilterParams(params)
  const queryString = limitParams + searchParams

  const queryKey = ['infinite-publications', queryString]
  const queryFn = async ({ pageParam }): Promise<PublicationResponse> => {
    return fetchOnClient<PublicationResponse>(
      `${ENDPOINT.PUBLICATION}/search?${queryString}&offset=${pageParam || 0}`
    )
  }

  return useInfiniteQuery<PublicationResponse, Error>({
    queryKey,
    queryFn,
    getNextPageParam: getNextPageParam,
    getPreviousPageParam: getPreviousPageParam,
    ...ConfigCacheOneDay,
    gcTime: 0,
    enabled: enabled,
    initialPageParam: 0,
  })
}

export const useFetchPublicationFilters = (): UseQueryResult<
  PublicationFilterResponse,
  Error
> => {
  return useQuery<PublicationFilterResponse, Error>({
    queryKey: ['publication-filters'],
    queryFn: async (): Promise<PublicationFilterResponse> =>
      fetchOnClient<PublicationFilterResponse>(
        `${ENDPOINT.PUBLICATION}/misc-filters`
      ),
    ...ConfigCacheOneDay,
  })
}
