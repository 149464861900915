export const BUKU_STATISTIK = {
  VALUE: 'PUBLICATION_TYPE_BUKU_STATISTIK',
  LABEL: 'Buku Statistik',
  ID: 1,
}

export const ANALISIS = {
  VALUE: 'PUBLICATION_TYPE_ANALISIS',
  LABEL: 'Analisis',
  ID: 2,
}

export const STATISTIK_ONLINE = {
  VALUE: 'PUBLICATION_TYPE_STATISTIK_ONLINE',
  LABEL: 'Statistik Online',
  ID: 3,
}

export const JENIS_DOCUMENT = [BUKU_STATISTIK, ANALISIS, STATISTIK_ONLINE]

export const MAP_JENIS_DOKUMEN_BY_VALUE = {
  PUBLICATION_TYPE_STATISTIK_ONLINE: 3,
  PUBLICATION_TYPE_ANALISIS: 2,
  PUBLICATION_TYPE_BUKU_STATISTIK: 1,
}

export const MAP_JENIS_DOKUMEN_BY_ID = {
  1: 'PUBLICATION_TYPE_BUKU_STATISTIK',
  2: 'PUBLICATION_TYPE_ANALISIS',
  3: 'PUBLICATION_TYPE_STATISTIK_ONLINE',
}

export const MAP_JENIS_DOKUMEN_ITEM_BY_VALUE = {
  PUBLICATION_TYPE_STATISTIK_ONLINE: STATISTIK_ONLINE,
  PUBLICATION_TYPE_ANALISIS: ANALISIS,
  PUBLICATION_TYPE_BUKU_STATISTIK: BUKU_STATISTIK,
}
