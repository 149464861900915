'use client'

import { Button } from '@wartek-id/button'
import { Separator } from '@wartek-id/separator'
import {
  Sheet,
  SheetBody,
  SheetCloseButton,
  SheetContent,
  SheetOverlay,
  SheetTitle,
} from '@wartek-id/sheet'
import { Text } from '@wartek-id/text'

import { FLAG_KEY_NEW_PDF_VIEWER } from '@/utils/toggle'
import { MAP_JENIS_DOKUMEN_ITEM_BY_VALUE } from '@/apis/data/jenisDocumentMapping'
import { Publication } from '@/apis/types'

import { ErrorDownloadDialog } from '@/components/ErrorDialog/ErrorDownloadDialog'
import { DynamicWrapper } from '@/components/PdfViewer/DynamicWrapper/DynamicWrapper'
import { defaultErrorMessages } from '@/components/PdfViewer/PdfViewer'
import { RedownloadDialog } from '@/components/RedownloadDialog/RedownloadDialog'
import { useIsActiveFF } from '@/components/WhitelistFfContext'

import { useDownloadPublikasi } from '../useDownloadPublikasi'
import { PdfFrame } from '../../../components/PdfFrame'

export function PreviewSheet({
  onClose,
  show,
  previewItem,
  onDownload,
}: {
  previewItem?: Publication | null
  show: boolean
  onClose: () => void
  onDownload: (pub: Publication) => void
}) {
  const { isActive: isUseNewPdfViewer } = useIsActiveFF({
    code: FLAG_KEY_NEW_PDF_VIEWER,
    envVal: process.env.NEXT_PUBLIC_FEATURE_PUB_NEW_PDF_VIEWER,
  })

  const {
    download,
    pdfUrl,
    isShowErrorDialog,
    closeErrorDialog,
    isShowRedownloadDialog,
    closeRedownloadDialog,
    confirmRedownload,
  } = useDownloadPublikasi({
    item: previewItem as Publication,
    onDownload,
  })

  return (
    <>
      <Sheet onClose={onClose} isOpen={show} sheetSize="xl">
        <SheetOverlay />
        <SheetContent className="overflow-auto">
          <SheetTitle
            as="header"
            style={{
              height: 'auto',
              minHeight: 'auto',
              padding: 0,
              flex: '1 1 auto',
            }}
            bordered
          >
            <div className="flex w-full flex-col gap-2 p-4">
              <div className="flex flex-wrap items-center gap-2">
                <Text color="subdued" data-testid={`txt-selected-year-mobile`}>
                  {previewItem?.yearPeriod}
                </Text>
                <Separator
                  style={{
                    width: 1,
                    height: 18,
                  }}
                />
                <Text color="subdued" data-testid={`txt-selected-type-mobile`}>
                  {
                    MAP_JENIS_DOKUMEN_ITEM_BY_VALUE[previewItem?.type || '']
                      ?.LABEL
                  }
                </Text>
              </div>

              <Text
                variant="heading-lg"
                data-testid={`txt-selected-title-mobile`}
              >
                {previewItem?.title}
              </Text>
            </div>
          </SheetTitle>

          <SheetBody>
            <div className="flex flex-col gap-4 px-4 py-4">
              <Text data-testid={`txt-selected-desc-mobile`}>
                {previewItem?.description}
              </Text>

              <div className="mb-8">
                {previewItem?.url && (
                  <>
                    {isUseNewPdfViewer ? (
                      <div className="space-y-4">
                        <Text variant="heading-md">Pratinjau PDF</Text>
                        <DynamicWrapper
                          url={pdfUrl}
                          errorMessages={defaultErrorMessages}
                          initialHeight={300}
                          initialWidth={360}
                          singlePage={true}
                        />
                      </div>
                    ) : (
                      <PdfFrame height={350} url={pdfUrl} />
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="sticky bottom-0 w-full border-t bg-white p-4">
              <Button
                fullWidth
                size="lg"
                onClick={download}
                data-href={pdfUrl}
                data-testid="btn-download-mobile"
              >
                Unduh Publikasi
              </Button>
            </div>
          </SheetBody>
          <SheetCloseButton data-testid="btn-close-sheet" />
        </SheetContent>
      </Sheet>

      <RedownloadDialog
        show={isShowRedownloadDialog}
        onClose={closeRedownloadDialog}
        onConfirm={confirmRedownload}
      />

      <ErrorDownloadDialog
        show={isShowErrorDialog}
        onClose={closeErrorDialog}
      />
    </>
  )
}
