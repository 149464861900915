import Storage from './storage'

function stripSpaceFromValue(value: string): string {
  return value.toLowerCase().replace(/\s/g, '_')
}

export function isExistInArrayStorage({
  key,
  itemToCheck,
}: {
  key: string
  itemToCheck: string
}): boolean {
  const storage = new Storage<Array<string>>(key, [])
  const currentValue = storage.getValue()

  if (currentValue && typeof currentValue === 'string') {
    return (
      stripSpaceFromValue(itemToCheck) === stripSpaceFromValue(currentValue)
    )
  } else if (currentValue && Array.isArray(currentValue)) {
    return currentValue.includes(stripSpaceFromValue(itemToCheck))
  }

  return false
}

export function storeArrayOfString({
  key,
  max,
  newItem,
}: {
  key: string
  max: number
  newItem: string
}): void {
  const storage = new Storage<Array<string>>(key, [])
  const currentValue = storage.getValue()

  const stripedNewItem = stripSpaceFromValue(newItem)

  if (currentValue && typeof currentValue === 'string') {
    storage.setValue([stripSpaceFromValue(currentValue), stripedNewItem])
  } else if (currentValue && Array.isArray(currentValue)) {
    let newItems = [...currentValue]

    if (!newItems.includes(stripedNewItem)) {
      newItems = [...currentValue, stripedNewItem]
    }

    if (newItems.length > max) {
      newItems = newItems.slice(1)
    }

    storage.setValue(newItems)
  } else {
    storage.setValue([stripedNewItem])
  }
}
