import { useState } from 'react'

import { downloadPdfFromCDN } from '@/utils/misc'
import {
  isExistInArrayStorage,
  storeArrayOfString,
} from '@/utils/storage-helpers'
import { Publication } from '@/apis/types'

import { useBotContext } from '@/components/BotDetector/BotProvider'

import { interceptAssetDomain } from './helpers'

const LATEST_DOWNLOADED_STORAGE_KEY = '__ldf_pb'
const MAX_STORAGE_ITEM = 10

export function useDownloadPublikasi({
  item,
  onDownload,
}: {
  item: Publication
  onDownload?: (pub: Publication) => void
}) {
  const [isShowRedownloadDialog, setShowRedownloadDialog] =
    useState<boolean>(false)
  const [isShowErrorDialog, setShowErrorDialog] = useState<boolean>(false)
  const { isBot } = useBotContext()

  const pdfUrl = interceptAssetDomain({
    url: item?.url?.trim() || '',
    type: 'pdf',
  })

  const coverUrl = interceptAssetDomain({
    url: item?.coverImageUrl?.trim() || '',
    type: 'cover',
  })

  const fileName = `Publikasi ${item?.title?.trim()}.pdf`

  const execDownload = async () => {
    await downloadPdfFromCDN({
      url: pdfUrl,
      filename: fileName,
      onSuccess: () => {
        onDownload?.(item as Publication)

        storeArrayOfString({
          key: LATEST_DOWNLOADED_STORAGE_KEY,
          max: MAX_STORAGE_ITEM,
          newItem: `${item.id}`,
        })
      },
      onFailed: () => {
        setShowErrorDialog(true)
      },
    })
  }

  const handleDownload = async () => {
    const isExistInStorage = isExistInArrayStorage({
      key: LATEST_DOWNLOADED_STORAGE_KEY,
      itemToCheck: `${item.id}`,
    })

    if (!isBot && isExistInStorage) {
      // show confirmation dialog
      setShowRedownloadDialog(true)
    } else {
      await execDownload()
    }
  }

  const closeErrorDialog = () => {
    setShowErrorDialog(false)
  }

  const closeRedownloadDialog = () => {
    setShowRedownloadDialog(false)
  }

  const confirmRedownload = async () => {
    setShowRedownloadDialog(false)
    await execDownload()
  }

  return {
    download: handleDownload,
    pdfUrl,
    coverUrl,
    // Error Dialog
    isShowErrorDialog,
    closeErrorDialog,
    // Confirmation Dialog
    isShowRedownloadDialog,
    closeRedownloadDialog,
    confirmRedownload,
  }
}
