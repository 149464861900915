import { API_BE_BASE_URL } from '@/configs/constants'

import { DefaultAppHeaders } from './fetcher.helpers'

export async function fetchOnClient<TResponse>(
  url: string,
  additionalHeaders: Record<string, string> = {},
  additionalOptions: Record<string, unknown> = {}
): Promise<TResponse> {
  try {
    const baseUrl = API_BE_BASE_URL

    const responseRaw = await fetch(`${baseUrl}${url}`, {
      method: 'GET',
      headers: {
        ...DefaultAppHeaders,
        ...additionalHeaders,
      },
      ...additionalOptions,
    })
    if (responseRaw.ok) {
      const response = await responseRaw.json()
      return response as TResponse
    } else {
      const response = await responseRaw.json()
      const message =
        response?.error?.message || 'Error while fetching data in the client'
      throw new Error(message)
    }
  } catch (e) {
    const message = `Error while fetching: ${API_BE_BASE_URL}${url} in the client, Error: ${e?.message}`
    throw new Error(message)
  }
}
