'use client'

import style from './heading-title.module.css'

import clsx from 'clsx'
import React, { ReactNode } from 'react'

import { Icon } from '@wartek-id/icon'
import { Link } from '@wartek-id/link'
import { Text } from '@wartek-id/text'

import { trackClickLinkFaq } from '@/utils/tracker/modules/common'
import {
  GAEventName,
  TrackerPageIndentifier,
  TrackerSectionType,
  TrackerSubSectionType,
} from '@/utils/tracker/shared'
import { useProductionRedirection } from '@/utils/useProductionRedirection'

import { FAQ_LINKS, UTM_SOURCE_FROM_PSD } from '@/configs/constants'

import { Container } from '../Container'
import { LinkWithTracker } from '../LinkWithTracker'

interface HeadingTitleBlockProps {
  title: string
  desc: string
  withFaqText?: boolean
  withBackButton?: boolean
  withMapOverlay?: boolean
  withIcon?: boolean
  icon?: ReactNode
  backButtonHref?: string
  className?: string
  trackerPageTitle: TrackerPageIndentifier
  trackerGaEventName: GAEventName
  trackerAdditionalParams?: {
    section?: TrackerSectionType
    sub_section?: TrackerSubSectionType
  }
}

export const HeadingTitleBlock = ({
  title,
  desc,
  withBackButton = false,
  withIcon = false,
  withMapOverlay = false,
  icon,
  backButtonHref = '',
  className = '',
  withFaqText = false,
  trackerPageTitle,
  trackerGaEventName,
  trackerAdditionalParams,
}: HeadingTitleBlockProps) => {
  useProductionRedirection()

  return (
    <section
      className={clsx(
        'relative bg-blue-90 py-16',
        className,
        withMapOverlay ? style.withMapOverlay : ''
      )}
      id="hero-section"
    >
      <Container removePadding={true}>
        <div className="flex flex-col gap-4 px-6 md:px-8">
          {withBackButton && backButtonHref && (
            <LinkWithTracker
              className="flex items-center gap-2 py-2"
              href={backButtonHref}
            >
              <Icon color="inverse">arrow_back</Icon>
              <Text color="inverse">Kembali</Text>
            </LinkWithTracker>
          )}
          <div className="mb-2 flex gap-2 md:items-center">
            {withIcon && icon ? <div>{icon}</div> : null}
            <Text variant="display-md" color="inverse" as="h1">
              {title}
            </Text>
          </div>
          {desc && (
            <div className="space-y-6">
              <Text variant="body" color="inverse-subdued">
                {desc}{' '}
                {withFaqText ? (
                  <>
                    Informasi lebih lanjut terkait data-data di dalam laman ini
                    dapat di akses melalui
                    <Link
                      variant="white"
                      href={`${FAQ_LINKS.COMMON}?${UTM_SOURCE_FROM_PSD}`}
                      rel="noopener noreferrer"
                      style={{ color: 'inherit' }}
                      target="_blank"
                      onClick={() => {
                        trackClickLinkFaq({
                          gaEventName: trackerGaEventName,
                          pageTitle: trackerPageTitle,
                          eventParams: trackerAdditionalParams,
                        })
                      }}
                    >
                      halaman FAQ
                    </Link>
                    ini.
                  </>
                ) : null}
              </Text>
            </div>
          )}
        </div>
      </Container>
    </section>
  )
}
