'use client'

import { Button } from '@wartek-id/button'
import { Icon } from '@wartek-id/icon'

export const ViewMoreButton = ({
  onClick,
  hasNextPage,
  isFetchingNextPage,
  isFetching,
}) => {
  if (hasNextPage) {
    return (
      <Button
        fullWidth
        color="white"
        size="lg"
        onClick={() => {
          onClick?.()
        }}
        disabled={isFetching || isFetchingNextPage}
        isLoading={isFetching || isFetchingNextPage}
        data-testid="btn-view-more"
      >
        Lihat Lebih
        <Icon>expand_more</Icon>
      </Button>
    )
  }

  return null
}
