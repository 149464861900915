import { ChangeEvent } from 'react'

import { Icon } from '@wartek-id/icon'
import { Select } from '@wartek-id/select'
import { Text } from '@wartek-id/text'

import { RQStatus } from '@/apis/types'

interface Props {
  loadingStatus: RQStatus
  value: string
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void
}

export const SelectSortBy = ({
  value = 'SORT_BY_CREATED_AT_DESC',
  onChange,
  loadingStatus,
}: Props) => {
  return (
    <div className="flex flex-col items-start gap-2 md:flex-row md:items-center">
      <Text variant="body-sm-bold" id="label-select-sort-by">
        Urutkan
      </Text>
      <div className="w-full md:w-[150px]">
        <Select
          data-testid="combo-sort-by"
          value={value}
          onChange={onChange}
          disabled={loadingStatus === 'pending'}
          dropdownIcon={<Icon>expand_more</Icon>}
          aria-label="Urutkan"
        >
          {[
            { id: 'SORT_BY_CREATED_AT_DESC', name: 'Terbaru' },
            { id: 'SORT_BY_CREATED_AT_ASC', name: 'Terlama' },
          ].map((item) => (
            <option value={`${item.id}`} key={item.id}>
              {item.name}
            </option>
          ))}
        </Select>
      </div>
    </div>
  )
}
