import { ErrorDialog } from './ErrorDialog'

const DEFAULT_ERROR_MESSAGE =
  'Maaf, terjadi kesalahan saat mengunduh berkas. Silahkan coba beberapa saat lagi atau unduh berkas lainnya.'

export const ErrorDownloadDialog = ({
  show,
  onClose,
  message = DEFAULT_ERROR_MESSAGE,
}: {
  show: boolean
  onClose: () => void
  message?: string
}) => {
  return (
    <ErrorDialog
      show={show}
      onClose={onClose}
      title="Gagal mengunduh berkas!"
      message={message}
    />
  )
}
