import { ChangeEvent } from 'react'

import { Icon } from '@wartek-id/icon'
import { Select } from '@wartek-id/select'
import { Text } from '@wartek-id/text'

import { RQStatus } from '@/apis/types'

interface FilterYearProps {
  loadingStatus: RQStatus
  value: string
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void
  yearPeriods?: number[]
}

export const FilterYear = ({
  value,
  onChange,
  loadingStatus,
  yearPeriods,
}: FilterYearProps) => {
  return (
    <div className="flex flex-col items-start gap-2 md:flex-row md:items-center">
      <Text variant="body-sm-bold" id="label-select-year">
        Periode
      </Text>
      <div className="w-full md:w-[150px]">
        <Select
          data-testid="combo-year"
          value={value}
          onChange={onChange}
          disabled={loadingStatus === 'pending'}
          dropdownIcon={<Icon>expand_more</Icon>}
          aria-label="Periode"
        >
          <option value="">Semua</option>
          {(yearPeriods || []).map((item) => (
            <option value={`${item}`} key={item}>
              {item}
            </option>
          ))}
        </Select>
      </div>
    </div>
  )
}
