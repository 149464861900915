'use client'

import { PublicationFilterResponse } from '@/apis/types'

import { InfinitePublication } from '@/modules/Publikasi/InfinitePublication'

import { DownloadSurveyProvider } from '@/components/DownloadSurvey/DownloadSurveyProvider'
import { HeadingTitleBlock } from '@/components/HeadingTitleBlock/HeadingTitleBlock'
import { PublikasiIcon } from '@/components/Icons/PublikasiIcon'

import { METATAG } from '@/configs/constants'

export default function PublikasiPage({
  initialYear,
  initialType,
  initialKeyword,
  initialSortBy,
  dataFilters,
}: {
  initialYear: string
  initialType: string
  initialKeyword: string
  initialSortBy: string
  dataFilters: PublicationFilterResponse
}) {
  return (
    <>
      <HeadingTitleBlock
        withIcon={true}
        withFaqText={true}
        withMapOverlay={true}
        icon={<PublikasiIcon />}
        title={METATAG.PUBLIKASI.title}
        desc={METATAG.PUBLIKASI.description}
        trackerGaEventName="publikasi"
        trackerPageTitle="Publikasi"
      />
      <DownloadSurveyProvider initialSource="PUBLIKASI">
        <InfinitePublication
          initialKeyword={initialKeyword}
          initialYear={initialYear}
          initialType={initialType}
          initialSortBy={initialSortBy}
          dataFilters={dataFilters}
        />
      </DownloadSurveyProvider>
    </>
  )
}
