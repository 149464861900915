'use client'

import styles from './masonry.module.css'

import clsx from 'clsx'
import { useRouter } from 'next/navigation'
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { Button } from '@wartek-id/button'
import { useMedia } from '@wartek-id/react-utils'

import { chunkChronological, mergePageData } from '@/utils/array'
import { xssSafeString } from '@/utils/string'
import {
  trackClickDownloadPublikasi,
  trackClickViewMorePublikasi,
  trackSearchPublikasi,
  trackViewPublikasi,
} from '@/utils/tracker/modules/publikasi'
import { Publication, PublicationFilterResponse } from '@/apis/types'
import { useFetchInifinitePublications } from '@/apis/usePublications'

import { UncontrolledSearchBox } from '@/components/CommonSearchBox/UncontrolledSearchBox'
import { Container } from '@/components/Container'
import { useDownloadSurvey } from '@/components/DownloadSurvey/DownloadSurveyProvider'
import { EmptyState } from '@/components/EmptyState'
import { ViewMoreButton } from '@/components/ViewMoreButton'

import { CardLoader } from './CardLoader'
import { PreviewResponsive } from './Preview/PreviewResponsive'
import { PublicationCard } from './PublicationCard'
import { FilterType } from './SearchAndFilter/FilterType'
import { FilterYear } from './SearchAndFilter/FilterYear'
import { SelectSortBy } from './SearchAndFilter/SelectSortBy'
const PER_PAGE = 8

const COLUMN_LG = 4
const COLUMN_MD = 2
const COLUMN_SM = 1

const renderPages = ({ pages, isLg, isMd, onPreview, onDownload }) => {
  const mergeArray = mergePageData(pages)
  const COLUMN = isLg ? COLUMN_LG : isMd ? COLUMN_MD : COLUMN_SM
  const chunkArray = chunkChronological(mergeArray, COLUMN)

  return (
    <>
      {chunkArray?.map((chunk, indexChunk) => (
        <div
          key={indexChunk}
          className={clsx(`segment segment-${COLUMN} segment--${indexChunk} `)}
        >
          {chunk.map((item, indexData) => {
            return (
              <PublicationCard
                key={item.id}
                item={item}
                index={`${indexChunk + 1}_${indexData + 1}`}
                onPreview={onPreview}
                onDownload={onDownload}
              />
            )
          })}
        </div>
      ))}
    </>
  )
}

export const InfinitePublication = ({
  initialYear,
  initialType,
  initialKeyword,
  initialSortBy,
  dataFilters,
}: {
  initialYear: string
  initialType: string
  initialKeyword: string
  initialSortBy: string
  dataFilters: PublicationFilterResponse
}) => {
  const mountedRef = useRef<boolean>(false)
  const router = useRouter()
  const { showSurvey } = useDownloadSurvey()

  const isMd = useMedia('(min-width: 768px)', false)
  const isLg = useMedia('(min-width: 1024px)', false)

  const containerRef = useRef(null)

  const [isFinishSetInitialData, setFinishSetInitialData] =
    useState<boolean>(false)

  const [keyword, setKeyword] = useState<string>(initialKeyword)
  const [selectedYear, setSelectedYear] = useState<string>(initialYear)
  const [selectedType, setSelectedType] = useState<string>(initialType)
  const [selectedSortBy, setSelectedSortBy] = useState<string>(initialSortBy)

  // To show preview dialog
  const [selectedItem, setSelectedItem] = useState<Publication | null>(null)
  const [isShowPreviewDialog, setIsShowPreviewDialog] = useState<boolean>(false)

  const getFilterSearchParams = useCallback(
    ({ customKeyword }: { customKeyword?: string }) => {
      const searchParams = new URLSearchParams()
      if (selectedType) {
        searchParams.set('type', selectedType)
      }

      if (selectedYear) {
        searchParams.set('year', selectedYear)
      }

      if (selectedSortBy) {
        searchParams.set('sort_by', selectedSortBy)
      }

      // Support custom keyword
      if (typeof customKeyword !== 'undefined') {
        if (customKeyword !== '') {
          searchParams.set('s', customKeyword)
        }
      } else if (keyword) {
        if (keyword !== '') {
          searchParams.set('s', keyword)
        }
      }

      const str = searchParams.toString()
      return str ? `?${str}` : '?'
    },
    [selectedType, selectedYear, selectedSortBy, keyword]
  )

  const {
    status,
    data,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useFetchInifinitePublications(
    {
      limit: PER_PAGE,
      filters: {
        query: keyword,
        periode: initialYear,
        type: initialType,
        sortBy: initialSortBy,
      },
    },
    {
      enabled: isFinishSetInitialData,
    }
  )

  const handleSubmitSearch = (newKeyword) => {
    setKeyword(newKeyword)
    if (newKeyword) {
      trackSearchPublikasi({
        keyword: newKeyword,
        jenis: selectedType,
        year: selectedYear,
      })
    }

    const searchParams = getFilterSearchParams({
      customKeyword: newKeyword,
    })

    router.replace(searchParams, { scroll: false })
  }

  const handleChangeYear = (e: ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.target.value
    setSelectedYear(newValue)
  }

  const handleChangeType = (e: ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.target.value
    setSelectedType(newValue)
  }

  const handleChangeSortBy = (e: ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.target.value
    setSelectedSortBy(newValue)
  }

  const handleClickTerapkan = () => {
    const searchParams = getFilterSearchParams({})
    router.replace(searchParams, { scroll: false })
  }

  const handleShowDialog = (item: Publication) => {
    setIsShowPreviewDialog(true)
    setSelectedItem(item)
  }

  const handleDownload = (item: Publication) => {
    trackClickDownloadPublikasi(item?.id || '')

    // Delay 1s before showing survey dialog
    setTimeout(() => {
      showSurvey('PUBLIKASI')
    }, 500)
  }

  const handleCloseDialog = () => {
    setIsShowPreviewDialog(false)
    setSelectedItem(null)
  }

  const isEmptyState =
    status === 'success' &&
    data &&
    data.pages.length === 1 &&
    data.pages[0].data.length === 0

  useEffect(() => {
    // Set initial keyword from query params
    if (initialKeyword) {
      const cleanKeyword = xssSafeString(initialKeyword)
      setKeyword(cleanKeyword)
    }

    setFinishSetInitialData(true)
  }, [initialKeyword])

  useEffect(() => {
    if (isFinishSetInitialData) {
      if (!mountedRef.current) {
        trackViewPublikasi({
          jenis: initialType,
          year: initialYear,
        })
        mountedRef.current = true
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinishSetInitialData])

  return (
    <>
      <section className="flex flex-col" id="search-filter-section">
        <section className="flex flex-col bg-gray-10" id="search-section">
          <UncontrolledSearchBox
            initialValue={keyword}
            placeholder={`Cari Publikasi: “Sanitasi Pendidikan Anak Usia Dini (PAUD) Tahun 2022”`}
            onSubmit={handleSubmitSearch}
          />
        </section>

        <section className="w-full border-b py-8" id="filter-section">
          <Container className="flex flex-col gap-2 md:flex-row">
            <FilterType
              value={selectedType}
              onChange={handleChangeType}
              loadingStatus={dataFilters ? 'success' : 'pending'}
            />
            <FilterYear
              value={selectedYear}
              onChange={handleChangeYear}
              loadingStatus={dataFilters ? 'success' : 'pending'}
              yearPeriods={dataFilters?.yearPeriods}
            />
            <SelectSortBy
              value={selectedSortBy}
              onChange={handleChangeSortBy}
              loadingStatus={dataFilters ? 'success' : 'pending'}
            />
            <Button
              color="black"
              onClick={handleClickTerapkan}
              className="max-w-[95px]"
              data-testid="btn-terapkan-filter"
              disabled={
                !isFinishSetInitialData || isFetching || isFetchingNextPage
              }
            >
              Terapkan
            </Button>
          </Container>
        </section>
      </section>

      <Container as="section" className="mb-16 mt-8" id="content-section">
        {status === 'pending' && <CardLoader perPage={PER_PAGE} />}

        {status === 'error' && (
          <EmptyState
            title="Oops, data tidak ditemukan"
            description="Coba kata kunci lain atau setel ulang filter yang Anda terapkan"
          />
        )}

        {status === 'success' && (
          <>
            <div
              className={clsx('mb-8 w-full', styles.masonry)}
              id="masonry-container"
              ref={containerRef}
            >
              {renderPages({
                pages: data.pages || [],
                isLg,
                isMd,
                onPreview: handleShowDialog,
                onDownload: handleDownload,
              })}
            </div>

            {isEmptyState ? (
              <EmptyState
                title="Oops, data tidak ditemukan"
                description="Coba kata kunci lain atau setel ulang filter yang Anda terapkan"
              />
            ) : (
              <div>
                <ViewMoreButton
                  onClick={() => {
                    trackClickViewMorePublikasi()
                    fetchNextPage()
                  }}
                  hasNextPage={hasNextPage}
                  isFetchingNextPage={isFetchingNextPage}
                  isFetching={isFetching}
                />
              </div>
            )}
          </>
        )}
      </Container>

      <PreviewResponsive
        show={isShowPreviewDialog}
        onClose={handleCloseDialog}
        previewItem={selectedItem}
        onDownload={handleDownload}
      />
    </>
  )
}
