'use client'

import { Button } from '@wartek-id/button'
import {
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogFooter,
  DialogOverlay,
} from '@wartek-id/dialog'
import { Separator } from '@wartek-id/separator'
import { Text } from '@wartek-id/text'

import { FLAG_KEY_NEW_PDF_VIEWER } from '@/utils/toggle'
import { MAP_JENIS_DOKUMEN_ITEM_BY_VALUE } from '@/apis/data/jenisDocumentMapping'
import { Publication } from '@/apis/types'

import { ErrorDownloadDialog } from '@/components/ErrorDialog/ErrorDownloadDialog'
import { PdfFrame } from '@/components/PdfFrame'
import { DynamicWrapper } from '@/components/PdfViewer/DynamicWrapper/DynamicWrapper'
import { defaultErrorMessages } from '@/components/PdfViewer/PdfViewer'
import { RedownloadDialog } from '@/components/RedownloadDialog/RedownloadDialog'
import { useIsActiveFF } from '@/components/WhitelistFfContext'

import { useDownloadPublikasi } from '../useDownloadPublikasi'

export function PreviewDialog({
  onClose,
  show,
  previewItem,
  onDownload,
}: {
  previewItem?: Publication | null
  show: boolean
  onClose: () => void
  onDownload: (pub: Publication) => void
}) {
  const { isActive: isUseNewPdfViewer } = useIsActiveFF({
    code: FLAG_KEY_NEW_PDF_VIEWER,
    envVal: process.env.NEXT_PUBLIC_FEATURE_PUB_NEW_PDF_VIEWER,
  })

  const {
    download,
    pdfUrl,
    isShowErrorDialog,
    closeErrorDialog,
    isShowRedownloadDialog,
    closeRedownloadDialog,
    confirmRedownload,
  } = useDownloadPublikasi({
    item: previewItem as Publication,
    onDownload,
  })

  return (
    <>
      <Dialog onClose={onClose} isOpen={show} size="md">
        <DialogOverlay />
        <DialogContent>
          <div className="flex flex-col gap-4 px-4 py-4">
            <div className="flex flex-wrap items-center gap-2">
              <Text color="subdued" data-testid={`txt-selected-year-desktop`}>
                {previewItem?.yearPeriod}
              </Text>
              <Separator
                style={{
                  width: 1,
                  height: 18,
                }}
              />
              <Text color="subdued" data-testid={`txt-selected-type-desktop`}>
                {
                  MAP_JENIS_DOKUMEN_ITEM_BY_VALUE[previewItem?.type || '']
                    ?.LABEL
                }
              </Text>
            </div>

            <Text
              variant="heading-lg"
              data-testid={`txt-selected-title-desktop`}
            >
              {previewItem?.title}
            </Text>

            <Text data-testid={`txt-selected-desc-desktop`}>
              {previewItem?.description}
            </Text>

            <div>
              {previewItem?.url && (
                <>
                  {isUseNewPdfViewer ? (
                    <div className="space-y-4">
                      <Text variant="heading-md">Pratinjau PDF</Text>
                      <DynamicWrapper
                        url={pdfUrl}
                        errorMessages={defaultErrorMessages}
                        initialHeight={400}
                        initialWidth={600}
                        singlePage={true}
                      />
                    </div>
                  ) : (
                    <PdfFrame url={pdfUrl} />
                  )}
                </>
              )}
            </div>
          </div>
          <DialogFooter>
            <Button
              fullWidth
              size="lg"
              onClick={download}
              data-href={pdfUrl}
              data-testid="btn-download-desktop"
            >
              Unduh Publikasi
            </Button>
          </DialogFooter>
          <DialogCloseButton data-testid="btn-close-dialog" />
        </DialogContent>
      </Dialog>

      <RedownloadDialog
        show={isShowRedownloadDialog}
        onClose={closeRedownloadDialog}
        onConfirm={confirmRedownload}
      />

      <ErrorDownloadDialog
        show={isShowErrorDialog}
        onClose={closeErrorDialog}
      />
    </>
  )
}
