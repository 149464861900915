import { useMedia } from '@wartek-id/react-utils'

import { Publication } from '@/apis/types'

import { PreviewDialog } from './PreviewDialog'
import { PreviewSheet } from './PreviewSheet'

export function PreviewResponsive({
  onClose,
  show,
  previewItem,
  onDownload,
}: {
  previewItem?: Publication | null
  show: boolean
  onClose: () => void
  onDownload: (p: Publication) => void
}) {
  const isMd = useMedia('(min-width: 768px)', false)

  return (
    <>
      {isMd && (
        <PreviewDialog
          show={show}
          onClose={onClose}
          onDownload={onDownload}
          previewItem={previewItem}
        />
      )}
      {!isMd && (
        <PreviewSheet
          show={show}
          onClose={onClose}
          onDownload={onDownload}
          previewItem={previewItem}
        />
      )}
    </>
  )
}
