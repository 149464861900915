import { getUniqueId } from '@/utils/misc'

import { sendTrackerGA } from '../gaTracker'
import { sendTrackerSatuEvent } from '../satuTracker'

export const trackViewPublikasi = ({ jenis, year }) => {
  const trackerId = getUniqueId()
  sendTrackerGA({
    id: trackerId,
    eventName: 'publikasi',
    eventAction: 'PAGE_VIEWED',
    eventParams: {
      eventLabel: `${jenis || 'semua'} - ${year || 'semua'}`,
    },
  })
  sendTrackerSatuEvent({
    id: trackerId,
    pageTitle: 'Publikasi',
    activityType: 'PAGE_VIEWED',
    eventParams: {
      jenis: jenis || 'semua',
      year: year || 'semua',
    },
  })
}

export const trackClickPreviewPublikasi = (label: string) => {
  const trackerId = getUniqueId()
  sendTrackerGA({
    id: trackerId,
    eventName: 'publikasi',
    eventAction: 'PREVIEW_BUTTON_CLICKED',
    eventParams: {
      eventLabel: label,
    },
  })

  sendTrackerSatuEvent({
    id: trackerId,
    pageTitle: 'Publikasi',
    activityType: 'PREVIEW_BUTTON_CLICKED',
    eventParams: {
      reference_id: label,
    },
  })
}

export const trackClickDownloadPublikasi = (label: string) => {
  const trackerId = getUniqueId()
  sendTrackerGA({
    id: trackerId,
    eventName: 'publikasi',
    eventAction: 'DOWNLOAD_BUTTON_CLICKED',
    eventParams: {
      eventLabel: label,
    },
  })

  sendTrackerSatuEvent({
    id: trackerId,
    pageTitle: 'Publikasi',
    activityType: 'DOWNLOAD_BUTTON_CLICKED',
    eventParams: {
      reference_id: label,
      format: 'pdf',
    },
  })
}

export const trackClickViewMorePublikasi = () => {
  const trackerId = getUniqueId()
  sendTrackerGA({
    id: trackerId,
    eventName: 'publikasi',
    eventAction: 'VIEW_MORE_BUTTON_CLICKED',
    eventParams: {},
  })
}

export const trackSearchPublikasi = ({ jenis, year, keyword }) => {
  if (keyword !== '') {
    const trackerId = getUniqueId()
    const eventLabel = `${keyword} - ${jenis || 'semua'} - ${year || 'semua'}`
    sendTrackerGA({
      id: trackerId,
      eventName: 'publikasi',
      eventAction: 'SEARCH_BUTTON_CLICKED',
      eventParams: {
        eventLabel: eventLabel,
      },
    })

    sendTrackerSatuEvent({
      id: trackerId,
      pageTitle: 'Publikasi',
      activityType: 'SEARCH_BUTTON_CLICKED',
      eventParams: {
        keyword,
        jenis: jenis || 'semua',
        year: year || 'semua',
      },
    })
  }
}
