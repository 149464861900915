'use client'

import clsx from 'clsx'
import { forwardRef, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import { Button } from '@wartek-id/button'
import { Card, CardBody, CardFooter } from '@wartek-id/card'
import { Separator } from '@wartek-id/separator'
import { Text } from '@wartek-id/text'

import { noop } from '@/utils/fn'
import { trackClickPreviewPublikasi } from '@/utils/tracker/modules/publikasi'
import { MAP_JENIS_DOKUMEN_ITEM_BY_VALUE } from '@/apis/data/jenisDocumentMapping'
import { Publication } from '@/apis/types'

import { ErrorDownloadDialog } from '@/components/ErrorDialog/ErrorDownloadDialog'
import { LazyImg } from '@/components/LazyImg'
import { RedownloadDialog } from '@/components/RedownloadDialog/RedownloadDialog'

import { useDownloadPublikasi } from './useDownloadPublikasi'

interface PublicationCardProps {
  item: Publication
  index: string
  onPreview: (p: Publication) => void
  onDownload: (p: Publication) => void
  className?: string
  onImageLoaded?: () => void
  onView?: () => void
}

type Ref = HTMLDivElement

export const PublicationCard = forwardRef<Ref, PublicationCardProps>(
  function PublicationCardInner(
    {
      item,
      index,
      onPreview,
      className,
      onImageLoaded = noop,
      onView,
      onDownload,
    },
    ref
  ) {
    const { ref: refImage, inView: isInView } = useInView({
      triggerOnce: true,
    })

    const {
      download,
      pdfUrl,
      coverUrl,
      isShowErrorDialog,
      closeErrorDialog,
      isShowRedownloadDialog,
      closeRedownloadDialog,
      confirmRedownload,
    } = useDownloadPublikasi({
      item: item as Publication,
      onDownload,
    })

    useEffect(() => {
      if (isInView) {
        onView?.()
      }
    }, [isInView, onView])

    return (
      <>
        <Card
          key={item.id}
          className={clsx(className)}
          data-testid={`publication-card-${index}`}
          id={`pub-${item.id}`}
          ref={ref}
        >
          <CardBody className="flex flex-col gap-4">
            <LazyImg
              alt={item.title.trim()}
              src={coverUrl}
              fallbackSrc={item.coverImageUrl.trim()}
              width={250}
              height={244}
              className="w-full rounded-t-lg"
              withWebp={false}
              onLoaded={onImageLoaded}
            />
            <div className="flex flex-wrap items-center gap-2" ref={refImage}>
              <Text
                variant="subheading"
                color="subdued"
                data-testid={`txt-year-${index}`}
              >
                {item?.yearPeriod}
              </Text>
              <Separator
                style={{
                  width: 1,
                  height: 18,
                }}
              />
              <Text
                variant="subheading"
                color="subdued"
                data-testid={`txt-type-${index}`}
              >
                {MAP_JENIS_DOKUMEN_ITEM_BY_VALUE[item?.type]?.LABEL}
              </Text>
            </div>
            <Text variant="heading-sm" data-testid={`txt-title-${index}`}>
              {item.title.trim()}
            </Text>
          </CardBody>
          <CardFooter className="flex flex-col flex-wrap gap-2 md:!flex-row">
            <Button
              color="black"
              onClick={() => {
                trackClickPreviewPublikasi(item.id)
                onPreview?.(item)
              }}
              data-testid={`btn-preview-${index}`}
            >
              Lihat
            </Button>
            <Button
              color="white"
              onClick={download}
              data-testid={`btn-download-${index}`}
              data-href={pdfUrl}
            >
              Unduh
            </Button>
          </CardFooter>
        </Card>

        <RedownloadDialog
          show={isShowRedownloadDialog}
          onClose={closeRedownloadDialog}
          onConfirm={confirmRedownload}
        />

        <ErrorDownloadDialog
          show={isShowErrorDialog}
          onClose={closeErrorDialog}
        />
      </>
    )
  }
)
