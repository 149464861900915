import {
  API_BE_BASE_URL,
  APP_SEMANTIC_VERSION,
  APP_SHORT_VERSION,
  BUILD_UNIX_TIME,
} from '@/configs/constants'

export const ENDPOINT = {
  STATISTIC: '/v1/statistics',
  PUBLICATION: '/v1/publications',
  DISTRICT: '/v1/districts',
  MISC_STATISTICS: '/v1/misc-statistics',
  DATA_INDUK_SATPEN: '/v1/master-data/satuan-pendidikan',
  DATA_INDUK_YAYASAN: '/v1/master-data/yayasan-pendidikan',
  DATA_INDUK_PTK: '/v1/master-data/ptk',
  RAPOR_PENDIDIKAN: '/v1/rapor-pendidikan',
}

export const FILE_CONTENT_TYPE = {
  CSV: 'text/csv',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PDF: 'application/pdf',
  csv: 'text/csv',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  pdf: 'application/pdf',
}

export const getNextPageParam = (firstPageResponse) => {
  const total = firstPageResponse?.meta?.total || 0
  const limit = firstPageResponse?.meta?.limit || 0
  const offset = firstPageResponse?.meta?.offset || 0

  const isLastPage = offset + limit > total
  const nextOffset = offset + limit

  return isLastPage ? undefined : nextOffset
}

export const getPreviousPageParam = (lastPageResponse) => {
  const limit = lastPageResponse?.meta?.limit || 0
  const offset = lastPageResponse?.meta?.offset || 0

  const isFirstPage = offset <= 0
  const previousOffset = offset - limit

  return isFirstPage ? undefined : previousOffset
}

export const isUseInternalEndpointFlag =
  process.env.NEXT_PUBLIC_FEATURE_USE_INTERNAL_ENDPOINT === 'y'

export const DefaultAppHeaders: Record<string, string> = {
  'X-Client-App': 'data-portal-fe',
  'X-Client-Deployed-At': `${BUILD_UNIX_TIME}`,
  'X-Client-Version': APP_SEMANTIC_VERSION,
  'X-Client-Sha': APP_SHORT_VERSION,
}

export async function fetchGetBlob(
  url: string,
  additionalHeaders: Record<string, string> = {},
  additionalOptions: Record<string, unknown> = {}
): Promise<Blob> {
  try {
    const responseRaw = await fetch(`${API_BE_BASE_URL}${url}`, {
      method: 'GET',
      headers: {
        ...DefaultAppHeaders,
        ...additionalHeaders,
      },
      ...additionalOptions,
    })

    if (responseRaw.ok) {
      const response = await responseRaw.blob()
      return response
    } else {
      const message = `Something went wrong while fetching blob: ${url}`
      throw new Error(message)
    }
  } catch (e) {
    const message = `Error while fetching blob: ${url}`
    throw new Error(message)
  }
}
