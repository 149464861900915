import { Button } from '@wartek-id/button'
import { Card, CardBody, CardFooter } from '@wartek-id/card'
import { Separator } from '@wartek-id/separator'
import { Shimmer } from '@wartek-id/shimmer'

import { range } from '@/utils/array'

export function CardLoader({ perPage = 4 }) {
  const arrayRange = range(1, perPage, 1) || [1, 2, 3, 4]

  return (
    <div
      className="mb-16 grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-4"
      data-testid="div-loader"
    >
      {arrayRange.map((i) => {
        return (
          <Card key={i} className="justify-between">
            <CardBody className="flex flex-col gap-4">
              {/* Image Loader */}
              <Shimmer width="100%" height={244} />
              <div className="flex flex-wrap items-center gap-2">
                <Shimmer width={50} height={20} />
                <Separator
                  style={{
                    width: 1,
                    height: 18,
                  }}
                />
                <Shimmer width={100} height={20} />
              </div>
              <div className="flex flex-col gap-2">
                <Shimmer width="100%" height={20} />
                <Shimmer width="100%" height={20} />
                <Shimmer width="100%" height={20} />
              </div>
            </CardBody>
            <CardFooter className="flex flex-col gap-2 md:!flex-row">
              <Button color="black">Lihat</Button>
              <Button
                as="a"
                color="white"
                href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                Unduh
              </Button>
            </CardFooter>
          </Card>
        )
      })}
    </div>
  )
}
