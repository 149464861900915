import { Button } from '@wartek-id/button'
import {
  Dialog,
  DialogAction,
  DialogCancel,
  DialogCloseButton,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
} from '@wartek-id/dialog'
import { Text } from '@wartek-id/text'

export const DEFAULT_REDOWNLOAD_MESSAGE = `Sistem kami mendeteksi bahwa Anda sudah pernah mengunduh berkas ini sebelumnya. Apakah Anda yakin ingin mengunduh ulang?`

export const RedownloadDialog = ({
  show,
  onClose,
  onConfirm,
  message = DEFAULT_REDOWNLOAD_MESSAGE,
}: {
  show: boolean
  onClose: () => void
  onConfirm: () => void
  message?: string
}) => {
  return (
    <Dialog isOpen={show} onClose={onClose}>
      <DialogOverlay />
      <DialogContent className="md:!w-[600px] md:!max-w-[600px]">
        <DialogHeader
          title="Unduh ulang berkas yang sama?"
          variant="compact"
          className="!pr-[50px]"
        />
        <div
          style={{
            padding: 20,
          }}
        >
          <Text>{message}</Text>
        </div>
        <DialogFooter className="gap-2">
          <DialogCancel
            as={Button}
            color="white"
            data-testid="btn-cancel-download"
            onClick={onClose}
          >
            Batalkan
          </DialogCancel>
          <DialogAction
            as={Button}
            data-testid="btn-confirm-redownload"
            onClick={onConfirm}
          >
            Ya, unduh ulang
          </DialogAction>
        </DialogFooter>
        <DialogCloseButton data-testid="btn-close-dialog" />
      </DialogContent>
    </Dialog>
  )
}
